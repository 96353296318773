import PropTypes from "prop-types"
import React from "react"
// import ShortlistOrganisationLogo from "./shortlist-logo"

const ShortlistOrganisation = ({ organisation, project, logo }) => (
  <div className="row align-items-center">
    <div className="col-12 py-3" style={{
      color: `#d23464`,
      fontFamily: `Din Pro`,
      fontWeight: `200`,
      fontSize: `.9rem`,
    }}>
      <h4 style={{
        fontSize: `1rem`,
        fontWeight: `700`
      }}>{organisation}</h4>
      {project}
    </div>
    {/* <div className="col-4">
      <div className="float-right" style={{
       WebkitBoxShadow: `0px 0px 5px 0px rgba(0,0,0,0.10)`,
       MozBoxShadow: `0px 0px 5px 0px rgba(0,0,0,0.10)`,
       boxShadow: `0px 0px 5px 0px rgba(0,0,0,0.10)`,
       width: `75px`,
       height: `75px`,
       margin: `1.4rem .4rem 1.4rem 0`
      }}>
        <ShortlistOrganisationLogo logo={logo} />
      </div>
    </div> */}
  </div>
)

ShortlistOrganisation.propTypes = {
  organisation: PropTypes.string,
}

ShortlistOrganisation.defaultProps = {
  organisation: `Pendulum Media`,
}

export default ShortlistOrganisation
